body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.nav-tabs .nav-item .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #E9C46A;
  background-color: black;
  border-color: black;
}

.nav-tabs {
  /* border-bottom: none; */
  border-color: black;
  background-color: #2b3750;
  border-bottom-width: 10px;
}

.nav-tabs .nav-link:hover{
  color: #E9C46A;
  border-color: black;
  border-color: black;
  
}
a {
  color: #E9C46A;
  text-decoration: none;
  background-color: transparent;
}


.nav-bar{
  height: auto;
  font-weight: 600;
  font-size: xx-large;
  font-family: Arial, Helvetica, sans-serif;
}

.close {
  color: #E9C46A; 
  opacity: 1;
}

.brand{
  font-size: x-large;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 15px;
}
.css-19uvqz0 {
  color: white;
}
.css-1bgn1ki-ShowMore {
  color: white;
}

.navbar-options-format {
  font-weight: 400;
  font-size: large;
  font-family: Arial, Helvetica, sans-serif;
}
.wallpaper-format {
  max-width: 100%;
}
.wallpaper-text {
  text-align: center; 
  vertical-align: middle;
}
.about-me {
  position: relative;
  max-width: 100%;
  margin-top: 770px;
  height: auto;
  background-color: #0e172a; 
  opacity: 0.9;
  padding-bottom: 50px;
  box-shadow: 20px 20px 20px 20px #0e172a; 
  
}
.about-me-two {
  position: relative;
  max-width: 100%;
  height: auto;
  padding: 40px 40px 40px 40px;
  opacity: 0.9;
  background-color: #14213d; 
  
}
.pop-up {
  position: relative;
  max-width: 90%;
  margin-top: -755px;
  margin-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #0e172a; 
  opacity: 0.75;
  box-shadow: 0px 0px 10px 10px #0e172a; 
  
}
.about-me-text {
  padding-top: 60px;
  font-family: Tahoma, Geneva, sans-serif;
  text-align: left;
  font-weight: 800px;
  font-size: 13;
  color: #f2ebdd;
}
.about-me-text-two {
  font-family: Tahoma, Geneva, sans-serif;
  text-align: left;
  font-weight: 800px;
  font-size: 13;
  color: #f2ebdd;
}
.sign-langauge-container {
  position: relative;
  max-width: 90%;
  margin-top: 100px;
  padding: 0px;
  left: 0px;
  height: 88vh;
  background-color:#14213d;
  
}
.tandem-container {
  position: relative;
  max-width: 90%;
  padding: 0px;
  left: 0px;
  height: 84vh;
  background-color:#14213d;
  opacity: 0.8;
  box-shadow: 0px 0px 10px 10px #14213d;
   
}
.microsoft-container {
  position: relative;
  max-width: 90%;
  margin-top: 100px;
  padding: 0px;
  left: 0px;
  height: 89vh;
  background-color:#0e172a;
  opacity: 0.8;
  box-shadow: 0px 0px 5px 5px #0e172a;
}
.project-container {
  box-shadow: 0px 0px 5px 5px #0e172a;
  height: auto;
  background-color:#0e172a;
  opacity: 0.8;
  width: 100%;
  
}
.ta-container {
  position: relative;
  max-width: 90%;
  padding: 0px;
  left: 0px;
  height: 74vh;
  background-color:#14213d;
  opacity: 0.8;
  box-shadow: 0px 0px 10px 10px #14213d;
}
.research-container {
  background-color:#0e172a;
  opacity: 0.9;
  box-shadow: 0px 0px 5px 5px #0e172a;
  line-height: 30px;
}
/* .sign-language-text {
  margin-left: 130px;
  margin-top: 50px;
  font-family: Tahoma, Geneva, sans-serif;
  text-align: left;
  font-weight: 800px;
  font-size: large;
  color: #f2ebdd;
  max-width: 100%;
} */
@media screen and (min-width: 601px) {
  .sign-language-text {
    margin-left: 130px;
    margin-top: 50px;
    font-family: Tahoma, Geneva, sans-serif;
    text-align: left;
    font-weight: 800px;
    font-size: large;
    color: #f2ebdd;
    max-width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .sign-language-text {
    margin-left: 130px;
    margin-top: 50px;
    font-family: Tahoma, Geneva, sans-serif;
    text-align: left;
    font-weight: 800px;
    font-size: small;
    color: #f2ebdd;
    max-width: 100%;
  }
}

@media screen and (min-width: 601px){
  .writing-text {
    position: 'absolute';
    width: "100%";
    color: #E9C46A;
    font-size: 45;

    line-height: 1.5;
  }
}
@media screen and (max-width: 600px){
  .writing-text {
    position: 'absolute';
    width: "100%";
    color: #E9C46A;
    font-size: 25;

    line-height: 1.5;
  }
}
.research-wallpaper-text {
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: auto;
  padding: auto;
  align-self: center;
  color:#f2ebdd; 
}
.card-image {
  border-radius: "10px";
  height: "90%"; 
  opacity: 0.7;
  background-color: black; 
}
.card-image:after {
  content:'\A';
  position:absolute;
  width:100%; height:100%;
  top:0; left:0;
  background:rgba(0,0,0,0.6);
  opacity:0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s; 
}
.card-image:hover:after {
  opacity:1;
  transition:  opacity 0.2s ease;
  display: block;
}
.award-card-image {
  border-radius: "10px";
  background-color: black; 
  color: #f2ebdd;
}
.award-card-image:after {
  content:'\A';
  position:absolute;
  width:100%; height:100%;
  top:0; left:0;
  background:rgba(0,0,0,0.6);
  opacity:0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s; 
}
.award-card-image:hover:after {
  opacity:1;
  transition:  opacity 0.2s ease;
  display: block;
}

.research-card-image {
  border-radius: "10px";
  background-color: black; 
  color: #f2ebdd;
}
/* .research-card-image:after {
  content:'\A';
  position:absolute;
  width:100%; height:100%;
  top:0; left:0;
  background:rgba(0,0,0,0.6);
  opacity:0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s; 
}
.research-card-image:hover:after {
  opacity:1;
  transition:  opacity 0.2s ease;
  display: block;
} */

.interest-card-image {
  border-radius: "10px";
  background-color: black; 
  color: #f2ebdd;
  height: auto;
}
.interest-card-image:after {
  content:'\A';
  position:absolute;
  width:100%; height:100%;
  top:0; left:0;
  background:rgba(0,0,0,0.6);
  opacity:0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s; 
}
.interest-card-image:hover:after {
  opacity:1;
  transition:  opacity 0.2s ease;
  display: block;
}

.research-image img {
  background-color: "white";
  transition: 0.5s all ease-in-out;
  -webkit-transition: 0.5s all ease-in-out;
  display: inline;
}

.research-image:hover img{
  transform: scale(1.5);
}

.img-hover-zoom {
  /*height: 300px; /* [1.1] Set it as per your need */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.5);
}
table {
  border-collapse: collapse;
  width: 100%;
}

table, th, td {
  border: 1px solid black;
}

th, td {
  padding: 8px;
  text-align: left;
}





